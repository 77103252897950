export class Url{

    url : string
    arguments: { string: string, value: any }[]

    constructor(url:string){
        this.url = url
        this.arguments = []
    }

    add(name:string,value:any){
        this.arguments.push({string:name,value:value})
        return this
    }

    build(){
        this.arguments.forEach((value) => {
            this.url += "&" + value.string + "=" + value.value
        })
        return this.url
    }

    public toString = () : string => {
        return this.url
    }

}
