// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body{
    min-height: 100vh;
    background: rgb(255,255,255);
    background: radial-gradient(circle, rgba(255,255,255,0.3006390056022409) 0%, rgba(65,125,241,0.49951855742296913) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#417df1",GradientType=1);
}

.item{
    display: flex;
    gap: 10px;
    margin-top: 18px;
    padding: 10px;
    border-radius: 5px;
    align-items: center;
    color: black;
}
a{
    text-decoration: none;
}
.item:hover{
    background-color: rgba(255,255,255,0.5);
}


`, "",{"version":3,"sources":["webpack://./src/components/Menu.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,4BAA4B;IAG5B,uHAAuH;IACvH,gHAAgH;AACpH;;AAEA;IACI,aAAa;IACb,SAAS;IACT,gBAAgB;IAChB,aAAa;IACb,kBAAkB;IAClB,mBAAmB;IACnB,YAAY;AAChB;AACA;IACI,qBAAqB;AACzB;AACA;IACI,uCAAuC;AAC3C","sourcesContent":["body{\n    min-height: 100vh;\n    background: rgb(255,255,255);\n    background: -moz-radial-gradient(circle, rgba(255,255,255,0.3006390056022409) 0%, rgba(65,125,241,0.49951855742296913) 100%);\n    background: -webkit-radial-gradient(circle, rgba(255,255,255,0.3006390056022409) 0%, rgba(65,125,241,0.49951855742296913) 100%);\n    background: radial-gradient(circle, rgba(255,255,255,0.3006390056022409) 0%, rgba(65,125,241,0.49951855742296913) 100%);\n    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=\"#ffffff\",endColorstr=\"#417df1\",GradientType=1);\n}\n\n.item{\n    display: flex;\n    gap: 10px;\n    margin-top: 18px;\n    padding: 10px;\n    border-radius: 5px;\n    align-items: center;\n    color: black;\n}\na{\n    text-decoration: none;\n}\n.item:hover{\n    background-color: rgba(255,255,255,0.5);\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
