import {CircularProgress, Tooltip} from "@mui/joy";

interface Props{
    title: string // LE TITRE DE LA CARD
    tooltip?: string
    children: JSX.Element
    refreshing: boolean
}

export function ParentDataCard({title, tooltip, refreshing, children} : Props){
    return (
        <div style={styles.container}>
                <div style={styles.headContainer}>
                    <Tooltip title={<span style={{ whiteSpace: 'pre-line' }}>{tooltip}</span>}>
                        <p style={styles.title}>{title}</p>
                    </Tooltip>
                </div>

                {
                    refreshing ?
                            (
                                <div style={styles.refresh}>
                                    <CircularProgress />
                                </div>
                            )
                        :
                            (
                               <div style={styles.element}>
                                   {children}
                               </div>
                            )
                }
        </div>
    )

}

const styles = {
    container:{
        backgroundColor: "rgb(255,255,255,0.5)",
        borderRadius: "20px",
        boxShadow: "0px 1px 15px 0px rgba(0, 0, 0, 0.25)",
    },
    element:{
        display: "flex",
        justifyContent: "space-between",
    },
    headContainer:{
        padding: 10,
        borderBottom: "solid rgba(153, 153, 153, 0.50) 1px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    title:{
        fontFamily: "Montserrat",
        fontSize: "16px",
        margin: "0",
        width: "fit-content",
        fontStyle: "normal",
        color: "#999999"
    },
    refresh: {
        display: "flex",
        justifyContent: "center",
        paddingTop: "10%"
    }
}
