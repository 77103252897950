import { TextBase, textStyle } from "../components/base/TextBase";
import { DataCard } from "../components/DataCard";
import React, { useEffect, useState } from "react";
import { EstablishmentModel } from "../models/EstablishmentModel";
import { useAppDispatch, useAppSelector } from "../store/hook";
import { StringDataChart } from "../components/StringDataChart";
import { SearchStringData, StyleSearchEnum } from "../components/SearchStringData";
import { httpClient } from "../httpClient";
import { setRefresh } from "../store/refreshButton";
import { UrlUtils } from "../utils/UrlUtils";
import { IOSSwitch } from "../components/IosSwitch";

export function Centre() {

    const maxDate = useAppSelector((state) => state.maxDate.value)
    const minDate = useAppSelector((state) => state.minDate.value)
    const refresh = useAppSelector((state) => state.refresh.value)
    const dispatch = useAppDispatch()

    const [nbOrg, setNbOrg] = useState<number>(0)
    const [nbPatAvgOrg, setNbPatAvgOrg] = useState<number>(0)
    const [dataCount, setDataCount] = useState<any[]>([])
    const [refreshing, setRefreshing] = useState<boolean>(true)
    const [filterArt51, setfilterArt51] = useState<boolean>(false)
    const [inclusionAverageNumberOfPatientsPerCenter, setInclusionAverageNumberOfPatientsPerCenter] = useState<number>(0)


    const urlUtils = new UrlUtils(minDate, maxDate)
    urlUtils.setArt51(filterArt51)

    useEffect(() => {

        if (refresh == false) return
        setRefreshing(true)

        httpClient.get(urlUtils.build("org")).then((response) => {

            setNbOrg(response.data.length)

        })
        httpClient.get(urlUtils.build("org/countPatient")).then((response) => {

            let data = response.data
            let nb = 0

            if (Array.isArray(data)) {
                data.map((value) => {
                    nb = nb + Number(value["patiennumber"]);
                })
            }

            setNbPatAvgOrg(nb / data.length)
            setDataCount(data)
            setRefreshing(false)
            dispatch(setRefresh(false))

        })

        httpClient.get(urlUtils.build("org/getInclusionAverageNumberOfPatientsPerCenter")).then((response) => {

            let data = response.data
            let nb = 0

            if (Array.isArray(data)) {
                data.map((value) => {
                    nb = nb + Number(value["nbpatientinclu"]);
                })
            }
            setInclusionAverageNumberOfPatientsPerCenter(nb / data.length)
            setRefreshing(false)
            dispatch(setRefresh(false))
        })

    }, [refresh]);


    return (
        <>
            <TextBase text={"Centre"} style={textStyle.title}/>
            <div style={styles.switchContainer}>
                <TextBase text={"Tout les centre"} style={textStyle.normal}/>
                <IOSSwitch
                    title={"test"}
                    sx={{m: 1}}
                    onChange={(e, check) => {
                        setfilterArt51(check)
                        dispatch(setRefresh(true))
                    }}
                />
                <TextBase text={"centre Walk Hop"} style={textStyle.normal}/>
            </div>

            <div style={styles.containerFlex}>
                <DataCard name={"Nombre de centre"}
                          data={nbOrg}
                          tooltip={"Centres créés entre deux dates"}
                          refresh={refreshing}
                />
                <DataCard name={"Inclusion moyenne de patient / centre"}
                          data={(inclusionAverageNumberOfPatientsPerCenter).toFixed(2)}
                          refresh={refreshing}
                          tooltip={"Nombre d'inclusion moyenne de patient par centre"}
                />

                <DataCard
                    name={"Patient moyen par centre"}
                    data={nbPatAvgOrg.toFixed(2)}
                    tooltip={"Nombre moyen de patient par centre"}
                    refresh={refreshing}
                />
            </div>

            <div style={styles.containerFlex}>
                <StringDataChart
                    data={dataCount}
                    title={["Patients créés par centre"]}
                    valueNames={["patiennumber"]}
                    stringName={"name"}
                    isSum={false}
                    horizontalBar={true}
                />
            </div>
            <div style={styles.containerFlex}>
                <StringDataChart
                    url={urlUtils.build("org/getTopFiveIncluded")}
                    title={["Top 5 des inclusions par établisement"]}
                    valueNames={["patients"]}
                    stringName={"name"}
                    isSum={false}
                    horizontalBar={true}
                />
            </div>

        </>
    )

}

const styles = {
    containerFlex: {
        display: "flex",
        justifyContent: "space-between",
        margin: 22
    },
    switchContainer: {
        padding: 3,
        backgroundColor: "",
        display: "flex",
        alignItems: 'center'
    }
}
