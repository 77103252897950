import { TextBase, textStyle } from "../components/base/TextBase";
import React, { useEffect, useState } from "react";
import { DataCard } from "../components/DataCard";
import { CircularDataCard } from "../components/CircularDataCard";
import { useAppDispatch, useAppSelector } from "../store/hook";
import { chartType, StringDataChart } from "../components/StringDataChart";
import { httpClient } from "../httpClient";
import { setRefresh } from "../store/refreshButton";
import { UrlUtils } from "../utils/UrlUtils";
import { IOSSwitch } from "../components/IosSwitch";
import DonutGroup, { DonutGroupsProps } from "../components/DonutGroup";
import { EstablishmentSearch } from "../components/EstablishmentSearch";
import {ParentDataCard} from "../components/ParentDataCard";
import axios from "axios";

const COLORS = ['#86A4DB', '#4974C4', '#033AA1', '#123370', '#132340',
    '#543028', '#A35748', '#F28D79', '#F7B3A6', '#FCDDD7',
    '#B8B8B8', '#5C5C5C', '#3D3D3D', '#1F1F1F', '#000000',
];

const DONUT_AGE_LABELS = {
    'less_than_30': 'Moins de 30 ans',
    '30_to_39': '30 - 39 ans',
    '40_to_49': '40 - 49 ans',
    '50_to_59': '50 - 59 ans',
    '60_to_69': '60 - 69 ans',
    '70_to_79': '70 - 79 ans',
    '80_to_89': '80 - 89 ans',
    '90_and_above': 'plus de 90 ans'
};

export function Patient() {

    const [ageAvg, setAgeAvg] = useState<number>(0);
    const [nbUser, setNbUser] = useState<number>(0);
    const [klmPatientToCenter, setKlmPatientToCenter] = useState<number>(0);
    const [totalKlmPatientToCenter, setTotalKlmPatientToCenter] = useState<number>(0);
    const [carbonePatientToCenter, setCarbonePatientToCenter] = useState<number>(0);
    const [nbUserInvoicing, setNbUserInvoicing] = useState<number>(0);
    const [nbUserIncluded, setNbUserIncluded] = useState<number>(0);
    const [nbAvgSeance, setNbAvgSeance] = useState<number>(0);
    const [refreshing, setRefreshing] = useState<boolean>(true)
    const [establishment, setEstablishment] = useState<number>()
    const [walkhop, setWalkhop] = useState<boolean>(false)
    const [filterArt51, setFilterArt51] = useState<boolean>(false)
    const [patientRisk, setPatientRisk] = useState<number>(0);
    const [averageAngioAndInclu, setAverageAngioAndInclu] = useState<number>(0);
    const [patientWithoutRisk, setPatientWithoutRisk] = useState<number>(0);
    const [ageGroup, setAgeGroup] = useState<DonutGroupsProps[]>();
    const [nbSessionsPerPatientForCompletedPrograms, setNbSessionsPerPatientForCompletedPrograms] = useState<number>(0)
    const [continueCRPatientsRequest, setContinueCRPatientsRequest] = useState<number>(0)
    const [continueCRDoctorsRequest, setContinueCRDoctorsRequest] = useState<number>(0)
    const [cardiologicalCause, setCardiologicalCause] = useState<number>(0)
    const [noCardiologicalCause, setNoCardiologicalCause] = useState<number>(0)
    const [exitPersonalReasons, setExitPersonalReasons] = useState<number>(0)
    const [sessionTypes, setSessionTypes] = useState<any>();
    const [rareScoreGroup, setRareScoreGroup] = useState<any>();
    const [avgTimeBelowUpperRate, setavgTimeBelowUpperRate] = useState<number | string>(0);
    const [RiskNumberRep, setRiskNumberRep] = useState<{ string: string, value1: number }[]>([])

    const [riskRepartition, setRiskRepartition] = useState<{ string: string, value1: number }[]>([])

    const maxDate = useAppSelector((state) => state.maxDate.value)
    const minDate = useAppSelector((state) => state.minDate.value)
    const refresh = useAppSelector((state) => state.refresh.value)
    const dispatch = useAppDispatch()

    const urlUtils = new UrlUtils(minDate, maxDate, establishment)
    urlUtils.setArt51(filterArt51)
    urlUtils.setWolkhop(walkhop)


    useEffect(() => {
        setContinueCRPatientsRequest(0)
        setContinueCRDoctorsRequest(0)
        setCardiologicalCause(0)
        setNoCardiologicalCause(0)
        setExitPersonalReasons(0)
    }, [establishment])

    useEffect(() => {

        if (refresh === false) return
        setRefreshing(true)

        const requestKlm = httpClient.get(urlUtils.build("user/avgDistence"))
            .then((r) => {
                setKlmPatientToCenter(r.data["avg"])
        })

        const requestTotalKlm = httpClient.get(urlUtils.build("user/totalDistance"))
            .then((r) => {
                setTotalKlmPatientToCenter(r.data["distance"])
                let carbone = r.data["distance"] * 218 / 1000000 // distance en km * masse carbone en grame / 1000 (pour mettre en tonne) pour une voiture thermique
                setCarbonePatientToCenter(carbone)
        })

        const averageAngioAndIncluRequest = httpClient.get(urlUtils.build("user/angioAndFirstSeance"))
            .then((response) => {
                setAverageAngioAndInclu(response.data[0]["average"])
            })

        const riskRepartitionRequest = httpClient.get(urlUtils.build("user/info/repartitionRisk"))
            .then((r) => {
                let dataRepRisk: { string: string, value1: number }[] = []
                dataRepRisk.push(
                    {string: "fumeur", value1: Number(r.data[0]["smoking"])},
                    {string: "highBloodPressure", value1: Number(r.data[0]["highBloodPressure"])},
                    {string: "hypercholesterolaemia", value1: Number(r.data[0]["hypercholesterolaemia"])},
                    {string: "stress", value1: Number(r.data[0]["stress"])},
                    {string: "diabetes", value1: Number(r.data[0]["diabetes"])},
                    {string: "Apnée du sommeil", value1: Number(r.data[0]["sleepApnea"])},
                )
                setRiskRepartition(dataRepRisk)
            })

        const riskNumberRepartitionRequest = httpClient.get(urlUtils.build("user/info/repartitionRiskNumber"))
            .then((r) => {
                let dataRepRisk: { string: string, value1: number }[] = []
                dataRepRisk.push(
                    {string: "0 risque", value1: Number(r.data[0]["nombre_de_patients"])},
                    {string: "1 risque", value1: Number(r.data[1] ? r.data[1]["nombre_de_patients"] :  0)},
                    {string: "2 risque", value1: Number(r.data[2] ? r.data[2]["nombre_de_patients"] : 0)},
                    {string: "3 risque", value1: Number(r.data[3]  ? r.data[3]["nombre_de_patients"] : 0)},
                    {string: "4 risque et plus", value1: Number(r.data[4] ? r.data[4]["nombre_de_patients"] : 0)}
                )
                console.log(dataRepRisk)
                setRiskNumberRep(dataRepRisk)
            })

        const request1 = httpClient.get(urlUtils.build("training/trainAvg"))
            .then((response) => {
                setNbAvgSeance(response.data[0]["avg"])
            })

        const request2 = httpClient.get(urlUtils.toUrl("user/count").add("type", "patient").build())
            .then((response) => {
                setNbUser(response.data[0]["patient"]);
            })

        const request3 = httpClient.get(urlUtils.toUrl("user/count").add("type", "age").build())
            .then((response) => {
                setAgeAvg(response.data[0]["age"]);
            })

        const request4 = httpClient.get(urlUtils.build("user/invoicing"))
            .then((response) => {
                setNbUserInvoicing(response.data[0]["invocingpatients"]);
            })

        const request6 = httpClient.get(urlUtils.build("user/allIncluded"))
            .then((response) => {
                const userincludeds = response.data.length
                setNbUserIncluded(userincludeds)
                setRefreshing(false)
            })

        const requestAgeGroup = httpClient.get(urlUtils.toUrl("user/count/age").build())
            .then((response) => {
                const data = response.data.elements.map((value: { label: string, count: number, rate: number }) => (
                    {
                        // @ts-ignore
                        label: DONUT_AGE_LABELS[value.label],
                        value: Math.round(value.rate * 100),
                        // @ts-ignore
                        color: DONUT_AGE_LABELS[value.label].color,
                        count: value.count,
                    }
                ));
                setAgeGroup(data)
            })

        const requestNbSessionsPerPatientForCompletedPrograms = httpClient.get(urlUtils.build("user/getCompletedPrograms"))
            .then((response) => {
                setNbSessionsPerPatientForCompletedPrograms(response.data[0]["averagesessioncount"])
            })

        const requestWithRisk = httpClient.get(urlUtils.toUrl("user/count").add("type", "with_risk").build())
            .then((response) => {
                setPatientRisk(response.data[0]["total"])
            })

        const requestWithoutRisk = httpClient.get(urlUtils.toUrl("user/count").add("type", "without_risk").build())
            .then((response) => {
                setPatientWithoutRisk(response.data[0]["total"])
            })
        const requestEarlyExitReason = httpClient.get(urlUtils.toUrl("user/getEarlyExitReason").build())
            .then((response) => {
                response.data.forEach((r: any) => {
                    switch (r.reason) {
                        case 'patient.medicalData.continueCRPatientsRequest':
                            setContinueCRPatientsRequest(parseFloat(r.percentage));
                            break;
                        case 'patient.medicalData.continueCRDoctorsRequest':
                            setContinueCRDoctorsRequest(parseFloat(r.percentage));
                            break;
                        case 'patient.medicalData.exitPersonalReasons':
                            setExitPersonalReasons(parseFloat(r.percentage));
                            break;
                        case 'patient.medicalData.noCardiologicalCause':
                            setNoCardiologicalCause(parseFloat(r.percentage));
                            break;
                        case 'patient.medicalData.cardiologicalCause':
                            setCardiologicalCause(parseFloat(r.percentage));
                            break;
                    }
                });
            })

        const requestSessionTypes = httpClient.get(urlUtils.toUrl("training/trainingType").build())
            .then((response) => {
                const data = response.data.elements.map((value: { label: string, count: number, rate: number }, idx: number) => (
                    {
                        label: value.label,
                        value:  Math.round(value.rate * 100),
                        color: COLORS[idx],
                        count: value.count
                    }
                ));
                setSessionTypes(data)
            })
        const requestRareScoreGroup = httpClient.get(urlUtils.toUrl("user/getRareScore").build())
            .then((response) => {

                const data = response.data.map((value: { label: string, value: string }, idx: number) => (
                    {
                        label: value.label.toString(),
                        value: parseInt(value.value),
                        color: COLORS[idx],
                    }
                ));
                setRareScoreGroup(data)
            })

        const timeSessionsBellowUpperHeart= httpClient.get(urlUtils.toUrl("user/count")
            .add("type","avg_session_below_upper_heart")
            .build())
            .then((r) => {
                setavgTimeBelowUpperRate(Number(r.data[0]["avg"]).toFixed(2))
            })

        Promise.all([
            request1,
            request2,
            request3,
            request4,
            request6,
            requestAgeGroup,
            requestNbSessionsPerPatientForCompletedPrograms,
            requestWithRisk,
            requestWithoutRisk,
            requestEarlyExitReason,
            requestSessionTypes,
            riskRepartitionRequest,
            requestRareScoreGroup,
            averageAngioAndIncluRequest,
            timeSessionsBellowUpperHeart,
            riskNumberRepartitionRequest,
            requestKlm,
            requestTotalKlm
        ]).then(() => {
            dispatch(setRefresh(false))
        }).catch(error => {
            console.error('Une erreur est survenue lors de l’exécution des requêtes :', error);
        });

    }, [refresh]);

    const urls = [
        urlUtils.build("user/allIncluded"),
        urlUtils.build("user/AllInvoicing")
    ];

    // @ts-ignore
    return (
        <div>
            <EstablishmentSearch
                setEstablishment={setEstablishment}
                setWalkhop={setWalkhop}
            />
            <div style={styles.title}>
                <TextBase text="Patient" style={textStyle.title}/>
            </div>
            <div style={styles.switchContainer}>
                <TextBase text={"Tout les patients"} style={textStyle.normal}/>
                <IOSSwitch
                    title={"test"}
                    sx={{m: 1}}
                    onChange={(e, check) => {
                        setFilterArt51(check)
                        dispatch(setRefresh(true))
                    }}
                />
                <TextBase text={"patients art51"} style={textStyle.normal}/>
            </div>
            <div>
                <div style={styles.containerFlex}>
                    <DataCard
                        data={nbUser}
                        name={"Nombre de patient"}
                        tooltip={"Patient crée entre deux date"}
                        refresh={refreshing}
                    />
                    <DataCard
                        data={nbUserInvoicing}
                        name={"Nombre de patient facturable"}
                        tooltip={"Patient ou sa première session effectuée est entre deux date"}
                        refresh={refreshing}
                    />
                    <DataCard
                        data={nbUserIncluded}
                        name={"Patient inclus"}
                        tooltip={"Patient avec un programme (ou la date de début est passée ou actuelle )"}
                        refresh={refreshing}
                    />
                </div>

                <div style={styles.containerFlex}>
                    <DataCard
                        data={patientWithoutRisk}
                        name={"Nombre de patient sans risque"}
                        tooltip={"Seul les patients avec une fiche patient sont pris en compte"}
                        refresh={refreshing}
                    />
                    <DataCard
                        data={Number(ageAvg).toFixed(2)}
                        name={"Age moyen"}
                        refresh={refreshing}
                        tooltip={"Age moyen de tout les patients"}
                    />
                    <DataCard
                        data={patientRisk}
                        name={"Nombre de patient a risque"}
                        tooltip={"Seul les patients avec une fiche patient sont pris en compte"}
                        refresh={refreshing}
                    />
                </div>
                <div style={styles.containerFlex}>
                    <StringDataChart
                        urls={urls}
                        title={["Patients inclus", "Patients facturables"]}
                        valueNames={["includeddate", "invocingdate"]}
                        isSum={true}
                        selectType={true}
                        defaultType={chartType.LINE}
                    />
                </div>
                <div style={styles.containerFlex}>
                    <DataCard
                        name={"Moyenne entre angioplasty et inclusion"}
                        data={Number(averageAngioAndInclu).toFixed(0)}
                        refresh={refreshing}
                        tooltip={"Temps moyen en jours entre l'angioplastie et l'inclusion d'un patient"}
                    />
                    <DataCard
                        name={"séances réalisées en moyenne"}
                        tooltip={"Nombre de séances réalisées en moyenne par programme terminé"}
                        data={Number(nbSessionsPerPatientForCompletedPrograms).toFixed(0)}
                        refresh={refreshing}
                    />

                    <DataCard
                        name={"Seance Moyenne"}
                        data={Number(nbAvgSeance).toFixed(2)}
                        tooltip={"Nombre de séance moyen par patient"}
                        refresh={refreshing}
                    />

                </div>

            </div>

            <div style={styles.containerFlex}>
                <ParentDataCard title="Pourcentage de patient par tranche d'age" refreshing={refreshing}>
                    <DonutGroup groups={ageGroup}/>
                </ParentDataCard>
                <ParentDataCard title="Pourcentage des activités" refreshing={refreshing}>
                    <DonutGroup groups={sessionTypes}/>
                </ParentDataCard>
            </div>

            <div style={styles.containerFlex}>
                <CircularDataCard
                    url={urlUtils.build("user/getBackToWork") + (filterArt51 ? "&type=art51" : "")}
                    isPercentage={true}
                    valueName={"back_to_work"}
                    title={"Reprise du travail après la TRC"}
                    tooltip={'Chiffre basé sur les champs temps partiel et temps plein renseigné dans le champs "Rythme de travail après reprise'}
                />
                <CircularDataCard
                    url={urlUtils.build("user/getPowerGain") + (filterArt51 ? "&type=art51" : "")}
                    isPercentage={true}
                    valueName={"power_gain"}
                    title={"Gain de puissance après la TRC"}
                    tooltip={'Permet de récupérer le pourcentage moyen de gain de puissance entre le test d’inclusion et de sortie'}
                />
                <CircularDataCard
                    url={urlUtils.build("user/getEarlyExit") + (filterArt51 ? "&type=art51" : "")}
                    isPercentage={true}
                    valueName={"early_exit"}
                    title={"Arrêt prématuré de la TRC"}
                    tooltip={`Poursuite en RC en présentiel sur demande du patient = ${continueCRPatientsRequest.toFixed(2)}%\n
                    Poursuite en RC en présentiel sur demande du médecin = ${continueCRDoctorsRequest.toFixed(2)}%\n
                    Cause médicale cardiologique = ${cardiologicalCause.toFixed(2)}%\n
                    Cause médicale non cardiologique = ${noCardiologicalCause.toFixed(2)}%\n
                    Raison personnelle = ${exitPersonalReasons.toFixed(2)}%`}
                />
            </div>
            <div style={styles.containerFlex}>
                <StringDataChart
                    title={["Répartition par facteur risque"]}
                    isSum={false}
                    datasets={riskRepartition}
                />
                <StringDataChart
                    title={["Répartition par nombre de risque"]}
                    isSum={false}
                    datasets={RiskNumberRep}
                />
            </div>
            <div style={styles.containerFlex}>
                <DataCard
                    name={"Distance moyenne à centre"}
                    data={(klmPatientToCenter / 100).toFixed(2) + " km"}
                    refresh={refreshing}
                    tooltip={"Distance moyenne entre le patient et le centre de santé"}
                />
                <DataCard
                    name={"Distance totale"}
                    data={(totalKlmPatientToCenter / 100).toFixed(2) + " km"}
                    refresh={refreshing}
                    tooltip={"Distance totale entre le patient et le centre de santé (calculée sur toute les séances des patients)"}
                />
                <DataCard
                    name={"Carbone total economisé"}
                    data={(carbonePatientToCenter).toFixed(2) + " T"}
                    refresh={refreshing}
                    tooltip={"Carbone total economisé en tonne"}
                />
            </div>
            <div style={styles.containerFlex}>
                <ParentDataCard title="Répartition des scores rares connus" refreshing={refreshing}>
                    <DonutGroup groups={rareScoreGroup}/>
                </ParentDataCard>
            </div>
            <DataCard
                name={"Temps moyen en dessous de la fc"}
                data={avgTimeBelowUpperRate}
                refresh={refreshing}
                tooltip={"Temps moyen par séance où les patients sont en dessous de leur fréquence cardiaque de sécurité"}
            />

        </div>

    )

}

const styles = {
    containerFlex: {
        display: "flex",
        justifyContent: "space-between",
        margin: 22,
        gap: 10
    },
    title: {
        display: "flex",
        gap: 10,
        alignItems: 'center'
    },
    switchContainer: {
        padding: 3,
        display: "flex",
        alignItems: 'center'
    },
    labelContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "1rem"
    },
    percentLabel: {
        fontSize: "18px",
    }
}





