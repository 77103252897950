import {useState} from "react";
import "./Menu.css"
import {Link, Outlet} from "react-router-dom";
import {Button, Input} from "@mui/joy";
import {useAppDispatch, useAppSelector} from "../store/hook";
import {setMinDate} from "../store/dateSelectionMin";
import {setMaxDate} from "../store/dateSelectionMax";
import {TextBase, textStyle} from "./base/TextBase";
import {setRefresh} from "../store/refreshButton";


export function Menu(){

    const [selected , setSelected] = useState<number>(1)

    const maxDate = useAppSelector((state) => state.maxDate.value)
    const minDate = useAppSelector((state) => state.minDate.value)
    const refresh = useAppSelector((state) => state.refresh.value)
    const dispatch = useAppDispatch()

    return(
        <div style={{display: "flex"}}>
            <nav style={styles.navbarContainer}>
                <div style={styles.bodyContainer}>
                    <div>
                        <div style={styles.headContainer}>
                            <img width={40} height={40} src={require('../assets/icons/logo.png')} alt={"ensweet logo"}/>
                            <h1 style={styles.navTitle}>EnsweetDash</h1>
                        </div>
                        <ul style={styles.list}>

                            <Link to={``}>
                                <li className={"item"} onClick={() => {
                                    setSelected(1)
                                    dispatch(setRefresh(true))
                                }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25"
                                         style={selected === 1 ? styles.selectedsvg : styles.svg}>
                                        <path
                                            d="M9.37501 22.9167H15.625C20.8333 22.9167 22.9167 20.8333 22.9167 15.625V9.375C22.9167 4.16667 20.8333 2.08333 15.625 2.08333H9.37501C4.16668 2.08333 2.08334 4.16667 2.08334 9.375V15.625C2.08334 20.8333 4.16668 22.9167 9.37501 22.9167Z"
                                            strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path
                                            d="M13.125 19.2708H17.0833C18.6458 19.2708 19.2708 18.6458 19.2708 17.0833V15.2083C19.2708 13.6458 18.6458 13.0208 17.0833 13.0208H13.125C11.5625 13.0208 10.9375 13.6458 10.9375 15.2083V17.0833C10.9375 18.6458 11.5625 19.2708 13.125 19.2708Z"
                                            strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                    Overview
                                </li>
                            </Link>

                            <Link to={`patient`}>
                                <li className={"item"} onClick={() => {
                                    setSelected(2)
                                    dispatch(setRefresh(true))
                                }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none"
                                         style={selected === 2 ? styles.selectedsvg : styles.svg}>
                                        <path
                                            d="M12.5 12.5C15.3765 12.5 17.7083 10.1681 17.7083 7.29167C17.7083 4.41518 15.3765 2.08333 12.5 2.08333C9.62351 2.08333 7.29166 4.41518 7.29166 7.29167C7.29166 10.1681 9.62351 12.5 12.5 12.5Z"
                                            strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path
                                            d="M21.4479 22.9167C21.4479 18.8854 17.4375 15.625 12.5 15.625C7.56251 15.625 3.55209 18.8854 3.55209 22.9167"
                                            strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                    Patient
                                </li>
                            </Link>

                            <Link to={`observance`}>
                                <li className={"item"} onClick={() => {
                                    setSelected(3)
                                    dispatch(setRefresh(true))
                                }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none"
                                         style={selected === 3 ? styles.selectedsvg : styles.svg}>
                                        <path d="M8.33334 2.08334V5.20834" strokeWidth="1.5" strokeMiterlimit="10"
                                              strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M16.6667 2.08334V5.20834" strokeWidth="1.5" strokeMiterlimit="10"
                                              strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M3.64584 9.46875H21.3542" strokeWidth="1.5" strokeMiterlimit="10"
                                              strokeLinecap="round" strokeLinejoin="round"/>
                                        <path
                                            d="M22.9167 19.7917C22.9167 20.5729 22.6979 21.3125 22.3125 21.9375C21.5938 23.1458 20.2708 23.9583 18.75 23.9583C17.6979 23.9583 16.7396 23.5729 16.0104 22.9167C15.6875 22.6458 15.4063 22.3125 15.1875 21.9375C14.8021 21.3125 14.5833 20.5729 14.5833 19.7917C14.5833 17.4896 16.4479 15.625 18.75 15.625C20 15.625 21.1146 16.1771 21.875 17.0417C22.5208 17.7812 22.9167 18.7396 22.9167 19.7917Z"
                                            strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round"
                                            strokeLinejoin="round"/>
                                        <path d="M17.125 19.7917L18.1562 20.8229L20.375 18.7708" strokeWidth="1.5"
                                              strokeLinecap="round" strokeLinejoin="round"/>
                                        <path
                                            d="M21.875 8.85417V17.0417C21.1146 16.1771 20 15.625 18.75 15.625C16.4479 15.625 14.5833 17.4896 14.5833 19.7917C14.5833 20.5729 14.8021 21.3125 15.1875 21.9375C15.4063 22.3125 15.6875 22.6458 16.0104 22.9167H8.33333C4.6875 22.9167 3.125 20.8333 3.125 17.7083V8.85417C3.125 5.72917 4.6875 3.64584 8.33333 3.64584H16.6667C20.3125 3.64584 21.875 5.72917 21.875 8.85417Z"
                                            strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round"
                                            strokeLinejoin="round"/>
                                        <path d="M12.4953 14.2708H12.5047" strokeWidth="2" strokeLinecap="round"
                                              strokeLinejoin="round"/>
                                        <path d="M8.6399 14.2708H8.64925" strokeWidth="2" strokeLinecap="round"
                                              strokeLinejoin="round"/>
                                        <path d="M8.6399 17.3958H8.64925" strokeWidth="2" strokeLinecap="round"
                                              strokeLinejoin="round"/>
                                    </svg>
                                    Observance
                                </li>
                            </Link>

                            <Link to={`centre`}>
                                <li className={"item"} onClick={() => {
                                    setSelected(4)
                                    dispatch(setRefresh(true))
                                }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24" fill="none"
                                         style={selected === 4 ? styles.selectedsvg : styles.svg}>
                                        <path d="M2 22h20M17 2H7C4 2 3 3.79 3 6v16h18V6c0-2.21-1-4-4-4Z" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"></path>
                                        <path d="M14.06 15H9.93c-.51 0-.94.42-.94.94V22h6v-6.06a.924.924 0 0 0-.93-.94ZM12 6v5M9.5 8.5h5" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"></path>
                                    </svg>
                                    Centre
                                </li>
                            </Link>

                            <Link to={`satisfaction`}>
                                <li className={"item"} onClick={() => {
                                    setSelected(7)
                                    dispatch(setRefresh(true))
                                }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none"
                                         style={selected === 7 ? styles.selectedsvg : styles.svg}>
                                        <path
                                            d="M5.125 21.125L6.96875 22.5521C7.20834 22.7917 7.73958 22.9062 8.10417 22.9062H10.3646C11.0833 22.9062 11.8542 22.375 12.0313 21.6563L13.4583 17.3125C13.7604 16.4791 13.2188 15.7604 12.3229 15.7604H9.9375C9.58334 15.7604 9.28125 15.4582 9.34375 15.0416L9.64584 13.1354C9.76042 12.6041 9.40625 11.9999 8.875 11.8229C8.39583 11.6458 7.80209 11.8854 7.5625 12.2396L5.125 15.875"
                                            strokeWidth="1.5" strokeMiterlimit="10"/>
                                        <path
                                            d="M2.08334 21.125V15.2917C2.08334 14.4584 2.43751 14.1562 3.27084 14.1562H3.86459C4.69793 14.1562 5.05209 14.4584 5.05209 15.2917V21.125C5.05209 21.9584 4.69793 22.2604 3.86459 22.2604H3.27084C2.43751 22.2604 2.08334 21.9688 2.08334 21.125Z"
                                            strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path
                                            d="M19.875 3.87496L18.0312 2.44788C17.7917 2.2083 17.2604 2.09375 16.8958 2.09375H14.6354C13.9167 2.09375 13.1458 2.62495 12.9687 3.3437L11.5417 7.68749C11.2396 8.52083 11.7812 9.23957 12.6771 9.23957H15.0625C15.4167 9.23957 15.7187 9.54172 15.6562 9.95838L15.3542 11.8646C15.2396 12.3958 15.5937 13 16.125 13.1771C16.6042 13.3542 17.1979 13.1146 17.4375 12.7604L19.875 9.125"
                                            strokeWidth="1.5" strokeMiterlimit="10"/>
                                        <path
                                            d="M22.9167 3.87494V9.70825C22.9167 10.5416 22.5625 10.8438 21.7292 10.8438H21.1354C20.3021 10.8438 19.9479 10.5416 19.9479 9.70825V3.87494C19.9479 3.04161 20.3021 2.73956 21.1354 2.73956H21.7292C22.5625 2.73956 22.9167 3.03119 22.9167 3.87494Z"
                                            strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                    Satisfaction
                                </li>
                            </Link>
                        </ul>
                    </div>
                    <div style={styles.dateSelection}>
                        <div style={styles.date}>
                            <TextBase text={"Date de début"} style={textStyle.normal}/>
                            <Input
                                type="date"
                                defaultValue={minDate.toISOString().split('T')[0]}
                                onChange={(event) => {
                                    if(event.target.valueAsDate) dispatch(setMinDate(event.target.valueAsDate))
                                }}
                            />
                        </div>
                        <div  style={styles.date}>
                            <TextBase text={"Date de fin"} style={textStyle.normal}/>
                            <Input
                                type="date"
                                defaultValue={maxDate.toISOString().split('T')[0]}
                                onChange={(event) => {
                                    if(event.target.valueAsDate) dispatch(setMaxDate(event.target.valueAsDate))
                                }}
                            />
                        </div>
                        <div>
                            <Button disabled={refresh} onClick={() => {dispatch(setRefresh(!refresh))}}>refresh</Button>
                        </div>
                    </div>
                </div>
            </nav>

            <div style={{width: "82%", padding:"4%", marginLeft: "20%",}}>
                <Outlet></Outlet>
            </div>

        </div>
    )


}

const styles = {

    navbarContainer:{
        width: "16%",
        maxWidth: "250px",
        height: "93vh",
        padding: 20,
        boxShadow: "0px 1px 15px 0px rgba(0, 0, 0, 0.25)",
        borderRadius: 20,
        position:"fixed" as 'fixed',
        backgroundColor: "rgb(255,255,255,0.5)"
    },
    bodyContainer:{
        display: "flex",
        flexDirection: 'column' as 'column',
        justifyContent: "space-between",
    },
    headContainer:{
        display: "flex",
        alignItems: "center",
        marginBottom: 40,
        gap: 5
    },
    navTitle:{
        margin: 0,
        fontFamily: "Montserrat",
        fontsize: "18px",
        fontWeight: 600,
        fontStyle: "normal",
        lineHeight: "16px"
    },
    list: {
        fontFamily: "Montserrat",
        fontsize: "16px",
        fontWeight: 500,
        fontStyle: "normal",
        lineHeight: "16px",
        listStyle: "none",
        paddingLeft: 5
    },
    svg:{
        stroke: "#999999",
        fill: "none"
    },
    selectedsvg:{
        stroke: "#2759BA",
        fill: "none"
    },
    dateSelection:{
        marginTop: 24,
        width: "75%"
    },
    date:{
        marginTop: 10,
    }
}
