import {createSlice, PayloadAction} from "@reduxjs/toolkit";

interface refreshState {
    value: boolean
}

const initialState: refreshState = {
    value: true,
}

export const refresh = createSlice({
    name: "refresh",
    initialState,
    reducers:{
        setRefresh: (state,action: PayloadAction<boolean>) =>{
            state.value = action.payload
        }
    }
})

export const {setRefresh} = refresh.actions
export default refresh.reducer
