import { Autocomplete, Button, TextField } from "@mui/joy";
import React, { useEffect, useState } from "react";
import { httpClient } from "../httpClient";
import { BaseEstablishmentModel } from "../models/EstablishmentModel";
import {useAppDispatch, useAppSelector } from "../store/hook";
import { setRefresh } from "../store/refreshButton";
import {UrlUtils} from "../utils/UrlUtils";
import {IOSSwitch} from "./IosSwitch";
import {Simulate} from "react-dom/test-utils";
import change = Simulate.change;
import {TextBase, textStyle} from "./base/TextBase";

type Props = {
    setEstablishment:  React.Dispatch<React.SetStateAction<number | undefined>>,
    setWalkhop:  React.Dispatch<React.SetStateAction<boolean>>,
}

export function EstablishmentSearch({setEstablishment , setWalkhop}: Props) {

    const [data, setData] = useState<BaseEstablishmentModel[]>([])
    const maxDate = useAppSelector((state) => state.maxDate.value)
    const minDate = useAppSelector((state) => state.minDate.value)
    const [isChecked, setIsChecked] = useState<boolean>(false)
    const dispatch = useAppDispatch()

    const urlUtils = new UrlUtils(new Date("01/01/2000"),new Date())

    useEffect(() => {
        httpClient.get(urlUtils.build("org")).then((r) => {
            setData(r.data)
        })
    }, []);

    const handleChange = (
        event: React.SyntheticEvent | null,
        newValue: any,
    ) => {
        if(newValue) {
            const estblisment = data.find(value => value.name == newValue)
            if(estblisment){
                setEstablishment(estblisment.id)
                dispatch(setRefresh(true))
            }
        }
    };

    return (
        <div style={styles.container}>
            <div style={styles.switchContainer}>
                <TextBase text={"tout les centres"} style={textStyle.normal}/>
                <IOSSwitch onChange={(e,check) => {
                    setEstablishment(undefined)
                    setWalkhop(check)
                    dispatch(setRefresh(true))
                    setIsChecked(check)
                }} title={"Walk Hop"}/>
                <TextBase text={"Walk Hop"} style={textStyle.normal}/>
            </div>
            <Autocomplete
                id="free-solo-demo"
                freeSolo
                onChange={handleChange}
                disabled={isChecked}
                options={data.map((option) => option.name)}
                style={{width:"50%", backgroundColor: "rgb(255,255,255,0.5)"}}
            />
            <Button onClick={() => {
                setEstablishment(undefined)
                dispatch(setRefresh(true))
            }}>Reset</Button>
        </div>

    )


}

const styles = {
    container: {
        display: "flex",
        justifyContent: "center",
        gap: 25,
        margin: 16,
        padding: 9,
        backgroundColor: "rgb(255,255,255,0.5)",
        borderRadius: 16,
    },
    switchContainer: {
        padding: 3,
        gap: 4,
        backgroundColor: "",
        display: "flex",
        alignItems: 'center'
    }
}
