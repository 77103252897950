import {CircularProgress, Tooltip} from "@mui/joy";
import React from "react";
import {faCircleInfo} from "@fortawesome/free-solid-svg-icons/faCircleInfo";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfo} from "@fortawesome/free-solid-svg-icons/faInfo";

interface Props{
    name: string
    data: number | string
    tooltip?: string
    refresh: boolean
}

export function DataCard({data,name,tooltip,refresh} : Props) {
    return(

        <div>
            { refresh ?
                (
                    <div style={styles.container}>
                        <div style={styles.refresh}>
                            <CircularProgress/>
                        </div>
                    </div>
                )
                :
                (
                    <div style={styles.container}>
                        <div style={styles.headContainer}>
                            {tooltip &&
                                <Tooltip title={tooltip} placement="top">
                                    <div style={{border: "1px solid rgba(0, 0, 0, 0.05)",borderRadius:5,padding:5,backgroundColor:"rgba(0, 0, 0, 0.02)"}}>
                                        <FontAwesomeIcon icon={faInfo}/>
                                    </div>
                                </Tooltip>
                            }
                            <p style={styles.title}>{name}</p>
                            <div style={styles.containIcon}></div>
                        </div>
                        <p style={styles.data}>{data}</p>
                    </div>
                )
            }
        </div>

    )
}

const styles = {
    container:{
        backgroundColor: "rgb(255,255,255,0.5)",
        padding: 20 ,
        borderRadius: "20px",
        width: "15vw",
        boxShadow: "0px 1px 15px 0px rgba(0, 0, 0, 0.25)"
    },
    title:{
        fontFamily: "Montserrat",
        fontSize: "16px",
        fontStyle: "normal",
        margin:0,
        color: "#999999"
    },
    data:{
        fontFamily: "Montserrat",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: "600",
        margin:0,
        color: "#1F1F1F"
    },
    headContainer:{
        display: "flex",
        gap: "10px",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "10px"
    },
    containIcon: {
        backgroundColor: "#033AA1",
        height: "30px",
        width: "30px",
        borderRadius: 8,
    },
    refresh: {
        display: "flex",
        justifyContent: "center",
        paddingTop: "10%"
    }
}

