import { BarChart, LineChart } from "@mui/x-charts";
import React, { useEffect, useState } from "react";
import { useAppSelector } from "../store/hook";
import { Select, Skeleton } from "@mui/joy";
import Option from "@mui/joy/Option";
import { httpClient } from "../httpClient";

export enum chartType {
    LINE = "line",
    BAR = "bar"
}

interface Props {
    url?: string
    urls?: string[]
    data?: any
    datasets? : { string: string, value1: number, value2: number }[] | {string: string, value1: number}[]
    title: string[]
    valueNames?: string[]
    stringName?: string
    isSum: boolean
    selectType?: boolean
    horizontalBar?: boolean
    defaultType?: chartType
}

export function StringDataChart({ url, urls, valueNames, data, isSum, stringName, title, selectType, horizontalBar, defaultType, datasets}: Props) {


    const [dataset, setDataset] = useState<
        { string: string, value1: number, value2: number }[]
        | { string: string, value1: number}[]>([]);
    const [datas, setDatas] = useState<any[]>([]);
    const [type, setType] = useState<chartType>(defaultType??chartType.BAR)
    const [refreshing, setRefreshing] = useState<boolean>(false)

    const refresh = useAppSelector((state) => state.refresh.value)


    useEffect(() => {
        if(datasets){
            setDataset(datasets)
        }
        else if (urls && urls.length === 2) {
            Promise.all(urls.map(url => httpClient.get(url))).then((responses) => {
                setDatas(responses.map(response => response.data));
            });
        }else if (url) {
            httpClient.get(url).then((response) => {
                setDatas([response.data]);
            });
        }else if (data) {
            setDatas([data,datasets]);
        }

    }, [data,datasets,refresh]);

    useEffect(() => {
        let initMap = new Map();

        if (Array.isArray(datas) && datas.length > 0 && valueNames) {
            datas.forEach((data, index) => {
                if(!Array.isArray(data)) return
                data.forEach((value: any) => {
                    let includeDate: Date

                    includeDate = new Date(value[valueNames[index]])
                    let month = includeDate.toLocaleString('default', { month: 'long' })
                    let year = includeDate.getFullYear()
                    let label = month + " " + year

                    if (!stringName && isSum) {
                        if (initMap.get(label) == null) {
                            initMap.set(label, { value1: index === 0 ? 1 : 0, value2: index === 1 ? 1 : 0 })
                        } else {
                            let current = initMap.get(label);
                            if (index === 0) {
                                current.value1 += 1;
                            } else {
                                current.value2 += 1;
                            }
                            initMap.set(label, current);
                        }
                    } else if (stringName && !isSum) {
                        let label = value[stringName]
                        let valueElement = value[valueNames[index]]
                        initMap.set(label, index === 0 ? { value1: Number(valueElement), value2: 0 } : { value1: 0, value2: Number(valueElement) });
                    }
                })
            })

            let datasets: { string: string, value1: number}[] = []
            initMap.forEach((value, key) => {
                datasets.push({ string: key, ...value })
            })
            setDataset(datasets)
        }

    }, [datas]);

    const handleChange = (
        event: React.SyntheticEvent | null,
        newValue: chartType | null,
    ) => {
        if (newValue) setType(newValue)
    };

    const series = [
        { dataKey: 'value1', label: `${title[0]}`, color: '#2759BA' },
        title[1] ? { dataKey: 'value2', label: `${title[1]}`, color: '#F28D79' } : null
    ].filter(Boolean) as Array<{ dataKey: string, label: string, color: string }>;

    return (
        <div style={valueNames && valueNames[0] === 'countAgeGroup' ? styles.componentSmallContainer : styles.componentContainer}>
            {selectType &&
                <div style={styles.selector}>
                    <Select defaultValue={defaultType ? defaultType : chartType.BAR} onChange={handleChange}>
                        <Option value={chartType.BAR}>Bar</Option>
                        <Option value={chartType.LINE}>Ligne</Option>
                    </Select>
                </div>
            }

            {refreshing ?

                (
                    <div style={styles.refresh}>
                        <Skeleton width={"60%"} height={300} />
                    </div>
                )
                :
                (
                    <>

                        {dataset.length > 0 ? (
                                <>
                                    {type === chartType.BAR &&
                                        <>
                                            {horizontalBar ?
                                                (
                                                    <BarChart
                                                        yAxis={[{ scaleType: 'band', dataKey: 'string' }]}
                                                        dataset={dataset}
                                                        height={valueNames && valueNames[0] === 'countAgeGroup' ? 300 : 500}
                                                        sx={{ padding: '20px' }}
                                                        series={series}
                                                        layout={"horizontal"}
                                                    />
                                                )
                                                :
                                                (
                                                    <BarChart
                                                        xAxis={[{ scaleType: 'band', dataKey: 'string' }]}
                                                        dataset={dataset}
                                                        height={300}
                                                        series={series}
                                                    />
                                                )
                                            }

                                        </>

                                    }
                                    {type === chartType.LINE &&
                                        <LineChart
                                            xAxis={[{ scaleType: 'point', dataKey: 'string' }]}
                                            yAxis={[{ min: 0 }]}
                                            dataset={dataset}
                                            height={300}
                                            series={series}
                                        />
                                    }

                                </>
                            )
                            :
                            (
                                <>
                                    {type === chartType.BAR &&
                                        <BarChart
                                            xAxis={[{ scaleType: 'band', dataKey: 'string' }]}
                                            dataset={[{ string: "mars", value1: 0, value2: 0 }]}
                                            height={300}
                                            series={series}
                                        />
                                    }
                                    {type === chartType.LINE &&
                                        <LineChart
                                            xAxis={[{ scaleType: 'point', dataKey: 'string' }]}
                                            yAxis={[{ min: 0 }]}
                                            dataset={[{ string: "mars", value1: 0, value2: 0 }]}
                                            height={300}
                                            series={series}
                                        />
                                    }

                                </>
                            )
                        }
                    </>
                )
            }
        </div>
    )
}

const styles = {
    componentContainer: {
        backgroundColor: "rgb(255,255,255,0.5)",
        borderRadius: "20px",
        width: "100%",
        boxShadow: "0px 1px 15px 0px rgba(0, 0, 0, 0.25)",
        padding: 16
    },
    componentSmallContainer:{
        backgroundColor: "rgb(255,255,255,0.5)",
        borderRadius: "20px",
        width: "50%",
        height: 350,
        boxShadow: "0px 1px 15px 0px rgba(0, 0, 0, 0.25)",
        padding: 16
    },
    selector: {
        width: 100
    },
    refresh: {
        height: 300,
        padding: 20
    }
}
