import {Url} from "./Url";

export class UrlUtils{

    minDate: Date
    maxDate: Date
    establishment: number | undefined
    art51: boolean | undefined
    walkhop: boolean | undefined

    constructor(minDate:Date, maxDate:Date, establishment?: number) {
        this.maxDate = maxDate
        this.minDate = minDate
        this.establishment = establishment
    }

    toUrl(path:string){

        let generatedUrl = process.env.REACT_APP_BACK_HOST+"/"+path+"?minDate=" + this.minDate.toJSON() + "&maxDate=" + this.maxDate.toJSON()

        if(this.walkhop !== undefined && this.walkhop) generatedUrl += "&walkhop=" + this.walkhop

        if(this.establishment) generatedUrl += "&establishment=" + this.establishment

        if(this.art51 !== undefined && this.art51) generatedUrl += "&art51=" + this.art51

        return new Url(generatedUrl)

    }

    build(path:string){

        return this.toUrl(path).build()

    }

    setArt51(bool:boolean){
        this.art51 = bool
    }

    setWolkhop(bool:boolean){
        this.walkhop = bool
    }



}
