import {DataCard} from "../components/DataCard";
import {LinearDataCard} from "../components/LinearDataCard";
import {CircularDataCard} from "../components/CircularDataCard";
import React, {useEffect, useState} from "react";
import {UserModel} from "../models/user.model";
import {TextBase, textStyle} from "../components/base/TextBase";
import {httpClient} from "../httpClient";
import {useAppDispatch, useAppSelector} from "../store/hook";
import { setRefresh } from "../store/refreshButton";
import { UrlUtils } from "../utils/UrlUtils";


export function Overview(){

    const maxDate = useAppSelector((state) => state.maxDate.value)
    const minDate = useAppSelector((state) => state.minDate.value)
    const refresh = useAppSelector((state) => state.refresh.value)
    const dispatch = useAppDispatch()

    const [ageAvg, setAgeAvg] = useState<number>(0);
    const [nbUser, setnbUser] = useState<number>(0);
    const [actif, setActif] = useState<number>(0);
    const [refreshing, setRefreshing] = useState<boolean>(true)
    const urlUtils = new UrlUtils(minDate,maxDate)

    useEffect(() => {

        if(refresh === false) return
        setRefreshing(true)


        httpClient.get(urlUtils.build("user/all")).then((response) => {
            const user = response.data as UserModel[]

            let avg = 0
            let actifs = 0

            user.forEach(value => {
                avg += value.age
                if(value.isactif){
                    actifs++
                }
            })

            setAgeAvg(avg/response.data.length)
            setActif(actifs)
            setnbUser(user.length)
            setRefreshing(false)
            dispatch(setRefresh(false))
        })


    }, [refresh]);


    return(
        <div>
            <TextBase text="BIENVENUE 👋" style={textStyle.title}/>
            <div style={styles.headContainer}>
                <DataCard
                    data={nbUser}
                    name={"Patient crée"}
                    tooltip={"Patient crée dans l'interval de temps"}
                    refresh={refreshing}
                />
                <DataCard
                    data={ageAvg.toFixed(2)}
                    name={"Age moyen"}
                    tooltip={"Age moyen des patients créés"}
                    refresh={refreshing}/>
                <DataCard
                    data={actif}
                    name={"Patient actif"}
                    tooltip={"Patient actif (la date pris en compte est la date de création du patient)"}
                    refresh={refreshing}
                />
            </div>
            <div style={styles.headContainer}>
                <LinearDataCard
                    url={urlUtils.build("user/all")}
                    valueName={"sex"}
                    firstCondition={"M"}
                    secondCondition={"F"}
                    firstColor={"blue"}
                    secondColor={"pink"}
                    titre={"Homme / Femme"}
                    datable={true}
                    dateNameValue={"createdAt"}
                />
                <CircularDataCard
                    url={urlUtils.build("training/completude")}
                    valueName={"completude"}
                    title={"Completude Moyenne"}
                    isPercentage={true}
                    tooltip={"Complétude sur toute les séances réalisées"}

                />
                <CircularDataCard
                    url={urlUtils.build("user/getPowerGain")}
                    isPercentage={true}
                    valueName={"power_gain"}
                    title={"Gain de puissance après la TRC"}
                    tooltip={'Permet de récupérer le pourcentage moyen de gain de puissance entre le test d’inclusion et de sortie'}
                />
            </div>
            <div style={styles.headContainer}>
                <CircularDataCard
                    url={urlUtils.toUrl("user/count").add("type","avg_art51").build()}
                    valueName={"art51"}
                    isPercentage={true}
                    title={"Patient Article51"}
                />
            </div>
        </div>

    );
}

const styles = {
    headContainer: {
        display: "flex",
        justifyContent: "space-between",
        margin: 22
    }
}
