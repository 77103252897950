import React from "react";
import { pieArcLabelClasses, PieChart } from "@mui/x-charts/PieChart";

export interface DonutGroupsProps {
    label: string,
    color: string
    value: number,
    count: number
}
export interface DonutProps {
    groups: DonutGroupsProps[] | undefined
}

const COLORS = ['#86A4DB', '#4974C4', '#033AA1', '#123370', '#132340',
    '#543028', '#A35748', '#F28D79', '#F7B3A6', '#FCDDD7',
    '#B8B8B8', '#5C5C5C', '#3D3D3D', '#1F1F1F', '#000000',
];

export default function DonutGroup ({ groups }:DonutProps) {
    const sizing = {
        margin: {right: 220},
        width: 480,
        height: 328,
    };

    return(
        <>
            <PieChart
                slotProps={{
                    legend: {
                        position: {
                            vertical: 'middle',
                            horizontal: 'right',
                        },
                        labelStyle: {
                            fontSize: 10,
                        },
                        markGap: 5,
                        itemGap: 2,
                    }
                }}
                series={[
                    {
                        data: groups ? groups.map((d, idx) => ({...d, color: COLORS[idx]})) : [],
                        highlightScope: {faded: 'global', highlighted: 'item'},
                        faded: {innerRadius: 0, additionalRadius: 0, color: 'gray'},
                        valueFormatter: (element) => `${element.value} %`
                    },
                ]}
                sx={{
                    [`& .${pieArcLabelClasses.root}`]: {
                        fill: 'white',
                        fontSize: 18,
                        padding: '20px'
                    },
                }}
                {...sizing}
            />
        </>
    )
}
