import {useEffect, useState} from "react";
import {useAppSelector} from "../store/hook";
import {httpClient} from "../httpClient";
import { CircularProgress } from "@mui/joy";


interface Props{
    url : string
    valueName: string
    firstCondition: string
    secondCondition: string
    titre: string
    firstColor?: string
    secondColor?: string
    datable?: boolean
    dateNameValue?: string

}

export function LinearDataCard({ url,valueName,firstCondition,secondCondition,secondColor,firstColor,titre,dateNameValue,datable }:Props){

    const [firstValue, setFirstValue] = useState<number>(0)
    const [secondValue, setSecondValue] = useState<number>(0)
    const [refreshing , setrefreshing] = useState<boolean>(true)

    const maxDate = useAppSelector((state) => state.maxDate.value)
    const minDate = useAppSelector((state) => state.minDate.value)
    const refresh = useAppSelector((state) => state.refresh.value)



    useEffect(() => {

        setrefreshing(true)
        let first = 0
        let second = 0

        httpClient.get(url).then((response) => {

            if(Array.isArray(response.data)){
                response.data.forEach((value) => {

                    if(datable && dateNameValue){

                        let dateData = new Date(value[dateNameValue].split("T")[0])

                        if(dateData >= minDate && dateData <= maxDate){
                            if(value[valueName] === firstCondition) first++
                            else if(value[valueName] === secondCondition) second++
                        }

                    }else{

                        if(value[valueName] === firstCondition) first++
                        else if(value[valueName] === secondCondition)second++

                    }


                })
                setFirstValue(Math.round((first*100)/(first+second)))
                setSecondValue(Math.round((second*100)/(first+second)))
                setrefreshing(false)
            }

        })

    }, [refresh]);

    return(

        <div style={styles.container}>
            <p style={styles.title}>{titre}</p>

            {
                refreshing ?
                        (
                            <>
                                <div style={styles.refresh}>
                                    <CircularProgress />
                                </div>
                            </>
                        )
                    :
                        (
                            <>
                                <div style={styles.datacontainer}>
                                    <div style={styles.textcontainer}>
                                        <p>{isNaN(firstValue) ? 0 : firstValue}%</p>
                                        <p>{isNaN(secondValue) ? 0 : secondValue}%</p>
                                    </div>
                                    <div style={styles.chartContainer}>
                                        <div style={{backgroundColor: firstColor, width: firstValue + "%",height: 10, borderRadius: 10}}>
                                        </div>
                                        <div style={{backgroundColor: secondColor, width: secondValue + "%", height: 10, borderRadius: 10, marginLeft : 5}}>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )
            }

        </div>

    )

}

const styles = {
    container:{
        backgroundColor: "rgb(255,255,255,0.5)",
        padding: 15 ,
        borderRadius: "20px",
        width: "20vw",
        boxShadow: "0px 1px 15px 0px rgba(0, 0, 0, 0.25)"
    },
    chartContainer:{
        alignItem:"center",
        display: "flex",
        width: "100%",
    },
    datacontainer:{
        marginTop: "8%",
    },
    textcontainer:{
        display: "flex",
        justifyContent: "space-between",
        fontFamily: "Montserrat",
        fontSize: "16px",
        fontWeight: 600,
        fontStyle: "normal",
    },
    title:{
        fontFamily: "Montserrat",
        fontSize: "16px",
        fontStyle: "normal",
        margin:0,
        color: "#999999"
    },
    refresh: {
        display: "flex",
        justifyContent: "center",
        paddingTop: "10%"
    }
}
