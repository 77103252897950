import {CircularProgress, Tooltip} from "@mui/joy";
import React, {useEffect, useState} from "react";
import { AxiosResponse } from "axios";
import {useAppSelector} from "../store/hook";
import {httpClient} from "../httpClient";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfo} from "@fortawesome/free-solid-svg-icons/faInfo";

//TODO A REFACTO AVEC LE BACK

interface Props{
    url?: string // URL API SI ON DOIT UTILISER UNE API
    data?: any // DATA SI PAS DE APPEL API NECESSAIRE
    isPercentage? : boolean // SI LA DATA TRAITER EST DEJA UN POUCENTAGE (ex : completude)
    valueName: string // NOM DU CHAMP A TRAITER
    title: string // LE TITRE DE LA CARD
    tooltip?: string
}


export function CircularDataCard({url,valueName, title, isPercentage,data ,tooltip} : Props){

    const [poucent, setPoucent] = useState< any>(0)
    const [refreshing , setrefreshing] = useState<boolean>(true)
    const [datas , setDatas] = useState<AxiosResponse<any,any>>()
    const refresh = useAppSelector((state) => state.refresh.value)

    function refreshData() {

        if (datas) {

            let sum = 0
            let nbMax = 0

            if (Array.isArray(datas)) {

                // Pas besoin de calcule
                if(datas[0] ){
                    if(!isPercentage){
                        setPoucent(Number( datas[0][valueName] * 100).toFixed(2))
                        setrefreshing(false)
                    }else{
                        setPoucent(Number( datas[0][valueName]).toFixed(2))
                        setrefreshing(false)
                    }

                    return
                }

            }
            setPoucent(parseFloat(sum.toFixed(1)))
            setrefreshing(false)
        }
    }

    useEffect(() => {
        setrefreshing(true)
        if(url){
            httpClient.get(url).then((response) => {
                setDatas(response.data)
            })
        }else{
            setDatas(data)
        }
    }, [refresh,data,url]);

    useEffect(() => {
        if(datas) {
            refreshData()
        }
        //eslint-disable-next-line
    }, [datas]);

    return(
        <div style={styles.container}>
            <div>
                <div style={styles.headContainer}>
                    {tooltip &&
                        <Tooltip title={tooltip} placement="top">
                            <div style={{border: "1px solid rgba(0, 0, 0, 0.05)",borderRadius:5,padding:5,backgroundColor:"rgba(0, 0, 0, 0.02)"}}>
                                <FontAwesomeIcon icon={faInfo}/>
                            </div>
                        </Tooltip>
                    }
                    <p style={styles.title}>{title}</p>
                </div>

                {
                    refreshing ?
                            (
                                <>
                                    <div style={styles.refresh}>
                                        <CircularProgress />
                                    </div>
                                </>
                            )
                        :
                            (
                               <>
                                       <div style={styles.circleContainer}>
                                           <CircularProgress
                                               determinate={!refreshing}
                                               variant={"solid"}
                                               value={poucent > 100 ? 100 : poucent}
                                               sx={{ '--CircularProgress-size': '100px'}}>
                                               <p style={{color:"#323232"}}>{poucent ? poucent + "%" : "NO DATA"}</p>
                                           </CircularProgress>
                                       </div>

                               </>
                            )
                }




            </div>
        </div>
    )

}

const styles = {
    container:{
        backgroundColor: "rgb(255,255,255,0.5)",
        borderRadius: "20px",
        width: "21.5vw",
        boxShadow: "0px 1px 15px 0px rgba(0, 0, 0, 0.25)",
    },
    compareContainer:{
        padding: 15,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    headContainer:{
        padding: 10,
        borderBottom: "solid rgba(153, 153, 153, 0.50) 1px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    title:{
        fontFamily: "Montserrat",
        fontSize: "16px",
        margin: "0",
        width: "fit-content",
        fontStyle: "normal",
        color: "#999999"
    },
    data:{
        fontFamily: "Montserrat",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: "600",
        margin:0,
        color: "#1F1F1F"
    },
    circleContainer:{
        padding: 15,
        display: "flex",
        justifyContent: "center",
    },
    refresh: {
        display: "flex",
        justifyContent: "center",
        paddingTop: "10%"
    }
}
