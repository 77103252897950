import {TextBase, textStyle} from "../components/base/TextBase";
import React, { useEffect, useState } from "react";
import {CircularDataCard} from "../components/CircularDataCard";
import {useAppDispatch, useAppSelector } from "../store/hook";
import {setRefresh} from "../store/refreshButton";
import { EstablishmentSearch } from "../components/EstablishmentSearch";
import { UrlUtils } from "../utils/UrlUtils";

export function Observance() {

    const maxDate = useAppSelector((state) => state.maxDate.value)
    const minDate = useAppSelector((state) => state.minDate.value)
    const refresh = useAppSelector((state) => state.refresh.value)
    const [establishment, setEstablishment] = useState<number>()
    const [walkhop, setWolkhop] = useState<boolean>(false)
    const dispatch = useAppDispatch()
    const urlUtils = new UrlUtils(minDate,maxDate,establishment)
    urlUtils.setWolkhop(walkhop)

    useEffect(() => {
        if(refresh == false) return
        dispatch(setRefresh(false))
    }, [refresh]);


    return(
        <div>
            <EstablishmentSearch
                setEstablishment={setEstablishment}
                setWalkhop={setWolkhop}
            />
            <TextBase text="Observance" style={textStyle.title}/>
            <div style={styles.container}>
                <CircularDataCard
                    url={urlUtils.build("program/GetObservcance")}
                    isPercentage={false}
                    valueName={"observance"}
                    title={"Observance Global"}
                    tooltip={"Observance moyenne sur toutes les séances"}
                />
                <CircularDataCard
                    url={urlUtils.toUrl("program/GetObservcance").add("type","medical").build()}
                    isPercentage={false}
                    valueName={"observance"}
                    title={"Observance Medical"}
                    tooltip={"Observance moyenne sur les séances en vélo des patients"}
                />
                <CircularDataCard
                    url={urlUtils.toUrl("program/GetObservcance").add("type","art51").build()}
                    isPercentage={false}
                    valueName={"observance"}
                    title={"Observance Art51"}
                    tooltip={"Observance moyenne sur les séances en vélo et les 16 premières séances des patients ART51"}
                />
            </div>
        </div>
    )

}
const styles = {
    container: {
        display: "flex",
        justifyContent: "space-between",
        margin: 22
    }
}
